/**
 * This file is auto generated using 'php artisan puddleglum:generate'
 *
 * Changes to this file will be lost when the command is run again
 */
// eslint-disable-next-line max-classes-per-file
import axios, { AxiosRequestConfig } from 'axios';

function transformToQueryString(params: Record<string, any>): string {
    return Object.entries(params)
        .filter(([, value]) => value !== null && value !== undefined)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value
                    .map(
                        (arrayItem) =>
                            `${encodeURIComponent(key)}[]=${encodeURIComponent(arrayItem)}`
                    )
                    .join('&');
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');
}

export type PaginatedResponse<T> = {
    current_page: number;
    data: T[];
    from: number;
    last_page: number;
    last_page_url: string | null;
    links: Array<{ url: string | null; label: string; active: boolean }>;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
};
export namespace Puddleglum.Models {
    export interface User {
        id: number;
        name: string;
        email: string | null;
        phone: string | null;
        truck_number: string | null;
        cdl: string | null;
        email_verified_at: string | null;
        password: string;
        last_login_at: string | null;
        remember_token: string | null;
        created_at: string | null;
        updated_at: string | null;
        stripe_id: string | null;
        pm_type: string | null;
        pm_last_four: string | null;
        trial_ends_at: string | null;
        temporary_truck_id: string | null;
        employee_number: number | null;
        is_active: boolean;
        roles?: Array<Puddleglum.Models.UserRole & { pivot: { [key: string]: any } }> | null;
        hauls?: Array<Puddleglum.Models.Haul> | null;
        recent_hauls?: Array<Puddleglum.Models.Haul> | null;
        pending_projects?: Array<Puddleglum.Models.Project> | null;
        devices?: Array<Puddleglum.Models.Device> | null;
        device?: Puddleglum.Models.Device | null;
        truck?: Puddleglum.Models.Truck | null;
        messages?: Array<Puddleglum.Models.Message> | null;
        unacknowledged_messages?: Array<Puddleglum.Models.Message> | null;
        temporary_truck?: Puddleglum.Models.Truck | null;
        active_truck?: Puddleglum.Models.Truck | null;
        roles_count?: number | null;
        hauls_count?: number | null;
        recent_hauls_count?: number | null;
        pending_projects_count?: number | null;
        devices_count?: number | null;
        messages_count?: number | null;
        unacknowledged_messages_count?: number | null;
    }

    export interface Notification {
        id: number;
        created_at: string | null;
        updated_at: string | null;
        user_id: number;
        entity_type: string;
        entity_id: number;
        title: string;
        message: string;
        read_at: string | null;
        deleted_at: string | null;
        type: string | null;
        user?: Puddleglum.Models.User | null;
    }

    export interface MaterialCode {
        code: string;
        description: string;
        unit_of_measure: string;
        use_tandem: boolean;
        created_at: string | null;
        updated_at: string | null;
    }

    export interface Broker {
        id: number;
        name: string;
        prefix: string;
        created_at: string | null;
        updated_at: string | null;
        is_active: boolean;
        trucks?: Array<Puddleglum.Models.Truck> | null;
        trucks_count?: number | null;
    }

    export interface Project {
        id: number;
        customer_id: number | null;
        name: string;
        haul_title: string | null;
        material: string;
        loading_location_id: number | null;
        unloading_location_id: number | null;
        unit_of_measure: string | null;
        amount_ordered: string | null;
        sst_buys_material: boolean;
        start_time: string | null;
        time_on_site: string | null;
        project_contact: string | null;
        project_contact_phone: string | null;
        number: string | null;
        po_number: string | null;
        created_by: number;
        archived_at: string | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        is_active: boolean;
        material_vendor_id: number;
        use_tandem: boolean;
        customer_job_number: string | null;
        customer?: Puddleglum.Models.Customer | null;
        hauls?: Array<Puddleglum.Models.Haul> | null;
        loading_location?: Puddleglum.Models.Location | null;
        unloading_location?: Puddleglum.Models.Location | null;
        days?: Array<Puddleglum.Models.ProjectDay> | null;
        material_vendor?: Puddleglum.Models.Vendor | null;
        hauls_count?: number | null;
        days_count?: number | null;
    }

    export interface DeviceUpdate {
        id: number;
        device_id: string;
        longitude: string;
        latitude: string;
        battery_percentage: string;
        is_foreground: boolean;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        trip_id: number | null;
        next_waypoint_id: number | null;
        proximity_to_next_waypoint: number | null;
        device?: Puddleglum.Models.Device | null;
        next_waypoint?: Puddleglum.Models.Location | null;
    }

    export interface Ticket {
        id: number;
        trip_id: number | null;
        ticket_type: string | null;
        ticket_date: string | null;
        verified_at: string | null;
        filename: string | null;
        scanned_fields: string | null;
        created_by: number;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        haul_id: number | null;
        units: string | null;
        driver_units: number | null;
        material_code: string | null;
        trip?: Puddleglum.Models.Trip | null;
        haul?: Puddleglum.Models.Haul | null;
        created_by_user?: Puddleglum.Models.User | null;
    }

    export interface UserRole {
        id: number;
        role_name: string;
        description: string;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
    }

    export interface Customer {
        id: number;
        name: string;
        phone: string;
        email: string;
        billing_id: string;
        created_by: number;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        customer_number: number | null;
        is_active: boolean;
    }

    export interface ProjectDay {
        id: number;
        project_id: number;
        date: string;
        number_of_trucks: number | null;
        quantity: number | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        note: string | null;
        is_active: boolean;
        project?: Puddleglum.Models.Project | null;
        hauls?: Array<Puddleglum.Models.Haul> | null;
        messages?: Array<Puddleglum.Models.Message> | null;
        hauls_count?: number | null;
        messages_count?: number | null;
    }

    export interface Trip {
        id: number;
        haul_id: number;
        started_at: string | null;
        arrived_loading_location_at: string | null;
        loaded_at: string | null;
        arrived_unloading_location_at: string | null;
        unloaded_at: string | null;
        completed_at: string | null;
        paused_at: string | null;
        cancelled_at: string | null;
        units: string | null;
        starting_latitude: string | null;
        starting_longitude: string | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        path_summary: string | null;
        signature: string | null;
        signature_at: string | null;
        is_active: boolean;
        haul?: Puddleglum.Models.Haul | null;
        tickets?: Array<Puddleglum.Models.Ticket> | null;
        location_updates?: Array<Puddleglum.Models.DeviceUpdate> | null;
        tickets_count?: number | null;
        location_updates_count?: number | null;
    }

    export interface Dispatch {
        id: number;
        haul_id: number;
        notification_id: number | null;
        viewed_at: string | null;
        created_by: number;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        haul?: Puddleglum.Models.Haul | null;
        driver?: Puddleglum.Models.User | null;
        created_by_user?: Puddleglum.Models.User | null;
        notification?: Puddleglum.Models.Notification | null;
    }

    export interface Truck {
        id: number;
        driver_id: number | null;
        type: string;
        truck_number: string | null;
        license_plate: string | null;
        status: string | null;
        base_latitude: string | null;
        base_longitude: string | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        is_insulated: boolean;
        broker_id: number | null;
        unavailable_until: string | null;
        status_updated_by: number | null;
        is_active: boolean;
        driver?: Puddleglum.Models.User | null;
        hauls?: Array<Puddleglum.Models.Haul> | null;
        broker?: Puddleglum.Models.Broker | null;
        temporary_driver?: Puddleglum.Models.User | null;
        hauls_count?: number | null;
    }

    export interface Location {
        id: number;
        name: string;
        address_1: string | null;
        address_2: string | null;
        city: string | null;
        state: string | null;
        zipcode: string | null;
        latitude: string;
        longitude: string;
        type: string;
        loading_location_cd: string | null;
        unloading_location_cd: string | null;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        geofence: number | null;
    }

    export interface Message {
        id: number;
        entity_type: string | null;
        entity_id: number | null;
        to_user_id: number;
        from_user_id: number;
        message: string;
        acknowledged_at: string | null;
        seen_at: string | null;
        sent_at: string | null;
        active_ind: boolean;
        created_at: string | null;
        updated_at: string | null;
        to_user?: Puddleglum.Models.User | null;
        from_user?: Puddleglum.Models.User | null;
    }

    export interface Vendor {
        id: number;
        type: string;
        code: string | null;
        name: string;
        active_ind: boolean;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        project?: Puddleglum.Models.Project | null;
        project_day?: Puddleglum.Models.ProjectDay | null;
        driver?: Puddleglum.Models.User | null;
        truck?: Puddleglum.Models.Truck | null;
        created_by_user?: Puddleglum.Models.User | null;
        trips?: Array<Puddleglum.Models.Trip> | null;
        dispatches?: Array<Puddleglum.Models.Dispatch> | null;
        notifications?: Array<Puddleglum.Models.Notification> | null;
        trips_count?: number | null;
        dispatches_count?: number | null;
        notifications_count?: number | null;
    }

    export interface Haul {
        id: number;
        project_day_id: number;
        truck_id: number | null;
        driver_id: number | null;
        declined_at: string | null;
        accepted_at: string | null;
        started_at: string | null;
        completed_at: string | null;
        cancelled_at: string | null;
        created_by: number;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
        trailer: string | null;
        decline_reason: string | null;
        is_active: boolean;
        project?: Puddleglum.Models.Project | null;
        project_day?: Puddleglum.Models.ProjectDay | null;
        driver?: Puddleglum.Models.User | null;
        truck?: Puddleglum.Models.Truck | null;
        created_by_user?: Puddleglum.Models.User | null;
        trips?: Array<Puddleglum.Models.Trip> | null;
        dispatches?: Array<Puddleglum.Models.Dispatch> | null;
        tickets?: Array<Puddleglum.Models.Ticket> | null;
        notifications?: Array<Puddleglum.Models.Notification> | null;
        trips_count?: number | null;
        dispatches_count?: number | null;
        tickets_count?: number | null;
        notifications_count?: number | null;
    }

    export interface Device {
        id: number;
        user_id: number;
        expo_push_token: string | null;
        device_name: string;
        device_type: string;
        has_location_permission: boolean;
        has_notification_permission: boolean;
        last_checkin_at: string | null;
        created_at: string | null;
        updated_at: string | null;
        device_id: string | null;
        latitude: string | null;
        longitude: string | null;
        user?: Puddleglum.Models.User | null;
        checkins?: Array<Puddleglum.Models.DeviceUpdate> | null;
        recent_checkins?: Array<Puddleglum.Models.DeviceUpdate> | null;
        most_recent_checkin?: Puddleglum.Models.DeviceUpdate | null;
        checkins_count?: number | null;
        recent_checkins_count?: number | null;
    }
}
export namespace Puddleglum.Requests {
    export interface StoreDriverRequest {
        name?: string;
        cdl?: string | null;
        phone?: string;
        truck_number?: string;
        truck_type?: string;
        license_plate?: string | null;
        truck_base_latitude?: string;
        truck_base_longitude?: string;
        is_insulated?: boolean | null;
        employee_number?: number | null;
    }

    export interface StoreTripRequest {
        haul_id: number;
        started_at?: string | null;
        arrived_loading_location_at?: string | null;
        loaded_at?: string | null;
        arrived_unloading_location_at?: string | null;
        unloaded_at?: string | null;
        completed_at?: string | null;
        paused_at?: string | null;
        cancelled_at?: string | null;
        starting_latitude?: string | null;
        starting_longitude?: string | null;
        units?: string | number | null;
        signature?: string | null;
    }

    export interface StoreMessageRequest {
        entity_type?: string | null;
        entity_id?: number | null;
        to_user_id: number;
        message: string;
    }

    export interface StoreVendorRequest {
        type?: string;
        code?: string | null;
        name: string;
    }

    export interface StoreTicketRequest {
        haul_id?: number | null;
        units?: string | null;
        driver_units?: number | null;
        ticket_type?: string | null;
        verified_at?: string | null;
        material_code?: string | null;
    }

    export interface StoreTruckRequest {
        type?: string;
        truck_number?: string;
        license_plate?: string | null;
        base_longitude?: number | null;
        base_latitude?: number | null;
        is_insulated?: boolean | null;
        broker_id?: number;
        status?: string | null;
        unavailable_until?: string | null;
        driver_id?: number | null;
    }

    export interface UpdateDriverRequest {
        name?: string;
        cdl?: string | null;
        phone?: string;
        truck_number?: string | null;
        employee_number?: number | null;
    }

    export interface StoreHaulRequest {
        project_day_id: number;
        driver_id?: number | null;
        truck_id?: number | null;
        accepted_at?: string | null;
        loaded_at?: string | null;
        unloaded_at?: string | null;
        cancelled_at?: string | null;
        completed_at?: string | null;
    }

    export interface StoreUserRequest {
        name?: string;
        email?: string;
        phone?: string | null;
        role_id?: number;
        employee_number?: number | null;
        password?: string;
    }

    export interface StoreLocationRequest {
        name?: string;
        address_1?: string | null;
        address_2?: string | null;
        city?: string | null;
        state?: string | null;
        zipcode?: string | null;
        latitude?: string | null;
        longitude?: string | null;
        loading_location_cd?: string | null;
        unloading_location_cd?: string | null;
        type?: string;
        geofence?: number | null;
    }

    export interface UpdateDeviceRequest {
        device_name?: string | null;
        device_type?: string | null;
        device_id?: string | null;
        has_notification_permission?: boolean | null;
        has_location_permission?: boolean | null;
        longitude?: string | null;
        latitude?: string | null;
        is_foreground?: boolean | null;
        broadcasting_state?: string | null;
        active_trip_id?: number | null;
    }

    export interface StoreProjectDayRequest {
        project_id: number;
        number_of_trucks?: number | null;
        quantity?: number | null;
        note?: string | null;
        start_time?: string | null;
        date?: string | null;
        trucks?: any | null;
    }

    export interface StoreCustomerRequest {
        name?: string;
        email?: string;
        phone?: string;
        customer_number?: number;
    }

    export interface StoreProjectRequest {
        number?: string | null;
        name?: string | null;
        material?: string | null;
        po_number?: string | null;
        unit_of_measure?: string | null;
        amount_ordered?: string | null;
        start_time?: string | null;
        project_contact?: string | null;
        project_contact_phone?: string | null;
        sst_buys_material?: boolean | null;
        time_on_site?: string | null;
        loading_location_id?: number | null;
        unloading_location_id?: number | null;
        customer_id?: number | null;
        haul_title?: string | null;
        material_vendor_id?: number | null;
        use_tandem?: boolean | null;
        customer_job_number?: string | null;
        days?: any | null;
    }

    export interface StoreBrokerRequest {
        name?: string;
        prefix?: string | null;
    }

    export interface StoreDispatchRequest {
        project_day_id: number;
        haul_id?: number | null;
    }
}
export namespace Puddleglum.Requests.Auth {
    export interface UserForgotPassword {
        email: string;
    }

    export interface UserLogin {
        email?: string;
        phone?: number;
        password: string;
    }
}
export namespace Puddleglum.Controllers {
    export class HaulController {
        static async index(
            request: {
                page: number;
                per_page: number;
                search: string;
                start_date: string;
                end_date: string;
            },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<PaginatedResponse<Puddleglum.Models.Haul>>(
                `/api/hauls?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/hauls/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreHaulRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/hauls`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            haul: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/hauls/${haul}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            haul: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/hauls/${haul}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            haul: string | number,
            request: Puddleglum.Requests.StoreHaulRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/hauls/${haul}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            haul: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/hauls/${haul}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async accept(
            request: { haul_id: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/haul/accept`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async decline(
            request: { haul_id: number; decline_reason: string },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/haul/decline`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async start(
            request: {
                haul_id: number;
                latitude: string;
                longitude: string;
                truck_number: string;
                trailer_number: string;
            },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/haul/start`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async stop(
            request: { haul_id: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/haul/stop`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async loaded(
            request: { haul_id: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/haul/loaded`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async unloaded(
            request: { haul_id: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/haul/unloaded`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async history(
            request: { page: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<PaginatedResponse<Puddleglum.Models.Haul>>(
                `/api/haul/history?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async myHauls(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Haul[]>(`/api/my-hauls`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class VendorController {
        static async index(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Vendor[]>(`/api/vendors`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/vendors/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreVendorRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/vendors`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            vendor: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/vendors/${vendor}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            vendor: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/vendors/${vendor}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            vendor: string | number,
            request: Puddleglum.Requests.StoreVendorRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/vendors/${vendor}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            vendor: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/vendors/${vendor}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class DriverController {
        static async index(
            request: { search: string; with_inactive: boolean },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.User>(
                `/api/drivers?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/drivers/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreDriverRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/drivers`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            driver: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/drivers/${driver}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            driver: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/drivers/${driver}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            driver: string | number,
            request: Puddleglum.Requests.UpdateDriverRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/drivers/${driver}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            driver: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/drivers/${driver}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async active(
            request: { page: number; per_page: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<PaginatedResponse<Puddleglum.Models.User>>(
                `/api/active-drivers?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
    }
    export class TruckController {
        static async index(
            request: {
                with_brokers: boolean,
                with_inactive: boolean,
                search: string
            },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Truck>(
                `/api/trucks?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/trucks/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreTruckRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/trucks`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            truck: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/trucks/${truck}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            truck: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/trucks/${truck}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            truck: string | number,
            request: Puddleglum.Requests.StoreTruckRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/trucks/${truck}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            truck: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/trucks/${truck}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async getAllTruckStatuses(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/getTruckStatuses`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class DispatchController {
        static async index(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/dispatches`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/dispatches/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreDispatchRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/dispatches`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            dispatch: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/dispatches/${dispatch}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            dispatch: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/dispatches/${dispatch}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            dispatch: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/dispatches/${dispatch}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            dispatch: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/dispatches/${dispatch}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class CustomerController {
        static async index(
            request: { search: string },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Customer>(
                `/api/customers?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/customers/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreCustomerRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/customers`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            customer: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/customers/${customer}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            customer: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/customers/${customer}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            customer: string | number,
            request: Puddleglum.Requests.StoreCustomerRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/customers/${customer}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            customer: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/customers/${customer}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class WebAppController {
        static async refreshUrl(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/mobile-url`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class MaterialCodeController {
        static async index(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.MaterialCode[]>(
                `/api/material-codes`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
    }
    export class LocationController {
        static async index(
            request: { search: string },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Location>(
                `/api/locations?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/locations/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreLocationRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/locations`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            location: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/locations/${location}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            location: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/locations/${location}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            location: string | number,
            request: Puddleglum.Requests.StoreLocationRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/locations/${location}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            location: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/locations/${location}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class MessageController {
        static async index(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/messages`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/messages/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreMessageRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/messages`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            message: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/messages/${message}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            message: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/messages/${message}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            message: string | number,
            request: Puddleglum.Requests.StoreMessageRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/messages/${message}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            message: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/messages/${message}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async acknowledge(
            message: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/message/${message}/acknowledge`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async seen(
            message: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/message/${message}/seen`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class ProjectDayController {
        static async index(
            request: { start_date: string; end_date: string },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/project-day?${transformToQueryString(request)}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/project-day/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreProjectDayRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/project-day`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            project_day: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.ProjectDay>(`/api/project-day/${project_day}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            project_day: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/project-day/${project_day}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            project_day: string | number,
            request: Puddleglum.Requests.StoreProjectDayRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/project-day/${project_day}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            project_day: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/project-day/${project_day}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async createNote(
            request: Puddleglum.Requests.StoreProjectDayRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/project-day/create-note`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class ProjectController {
        static async index(
            request: {
                start_date: string | undefined;
                end_date: string | undefined;
                search: string | undefined;
                with_inactive: boolean | undefined;
                with_archived: boolean | undefined;
            },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Project>(
                `/api/projects?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/projects/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreProjectRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/projects`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            project: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/projects/${project}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            project: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/projects/${project}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            project: string | number,
            request: Puddleglum.Requests.StoreProjectRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/projects/${project}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            project: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/projects/${project}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async clone(
            project: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/projects/clone/${project}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class TicketController {
        static async scan(
            ticket: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/ticket/scan/${ticket}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/ticket`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async batchVerify(
            request: { ids: string },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.patch(`/api/ticket/batch-verify`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            ticket: string | number,
            request: Puddleglum.Requests.StoreTicketRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/ticket/${ticket}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            id: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/ticket/${id}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async index(
            request: { date: string; type: string | undefined; is_verified: boolean | undefined },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/tickets?${transformToQueryString(request)}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            ticket: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/tickets/${ticket}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async rotate(
            ticket: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/tickets/rotate/${ticket}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async mine(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/my-tickets`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class TripController {
        static async index(
            request: {
                page: number;
                per_page: number;
                search: string;
                start_date: string;
                end_date: string;
                in_progress: boolean;
            },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<PaginatedResponse<Puddleglum.Models.Trip>>(
                `/api/trips?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/trips/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreTripRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/trips`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            trip: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/trips/${trip}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            trip: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/trips/${trip}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            trip: string | number,
            request: Puddleglum.Requests.StoreTripRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/trips/${trip}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            trip: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/trips/${trip}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async complete(
            request: { id: number; units: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/trip/complete`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class DeviceController {
        static async index(
            request: {
                page: number;
                per_page: number;
                start_date: string;
                end_date: string;
                device_id: number;
            },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<PaginatedResponse<Puddleglum.Models.DeviceUpdate>>(
                `/api/device-updates?${transformToQueryString(request)}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
        static async store(
            request: Puddleglum.Requests.UpdateDeviceRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/driver/device`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class AdminController {
        static async getAllUsers(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/users`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async getUserRoles(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/user-roles`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async addUser(
            request: Puddleglum.Requests.StoreUserRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/users`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            user: string | number,
            request: Puddleglum.Requests.StoreUserRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/users/${user}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class UserController {
        static async changePassword(
            request: { password: string; email: string },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/new-password`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async me(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.User>(`/api/me`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async logoutUser(
            request: { user_id: number },
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/user/logout?${transformToQueryString(request)}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async delete(
            user: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/users/${user}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class NotificationController {
        static async storeUserDevice(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/store-token`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async getNotifications(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Notification[]>(`/api/driver/notifications`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async markAsRead(
            notification: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put<Puddleglum.Models.Notification>(
                `/api/employee/notifications/${notification}`,
                {
                    headers: {
                        Precognition: validationOnly,
                        ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                    },
                    ...config,
                }
            );
        }
    }
    export class BrokerController {
        static async index(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<Puddleglum.Models.Broker[]>(`/api/broker`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async create(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/broker/create`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async store(
            request: Puddleglum.Requests.StoreBrokerRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/broker`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async show(
            broker: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/broker/${broker}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async edit(
            broker: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/broker/${broker}/edit`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async update(
            broker: string | number,
            request: Puddleglum.Requests.StoreBrokerRequest,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.put(`/api/broker/${broker}`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async destroy(
            broker: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.delete(`/api/broker/${broker}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
    export class ImageController {
        static async show(
            filename: string | number,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get(`/api/image/${filename}`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
}
export namespace Puddleglum.Controllers.Auth {
    export class LoginController {
        static async login(
            request: Puddleglum.Requests.Auth.UserLogin,
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.post(`/api/login`, request, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
        static async logout(
            validationOnly: boolean = false,
            fieldToValidate: string = '',
            config: AxiosRequestConfig = {}
        ) {
            return axios.get<{ message: string }>(`/api/logout`, {
                headers: {
                    Precognition: validationOnly,
                    ...(fieldToValidate ? { 'Precognition-Validate-Only': fieldToValidate } : {}),
                },
                ...config,
            });
        }
    }
}
